@import url("../shared.css");

@media screen {
	:root[data-layout="right-sidebar"] > body.grid {
		grid-template-areas: 'header header header' 'nav nav nav' '. main sidebar' 'footer footer footer';
		grid-template-rows: minmax(var(--header-height, 400px), auto) var(--nav-height, 4rem) minmax(var(--main-height, 75vh), auto) minmax(var(--footer-height, 300px), auto);
		grid-template-columns: auto minmax(var(--main-min-width, 320px), var(--main-max-width, 1200px)) minmax(var(--sidebar-min-width, 300px), var(--sidebar-max-width, 400px));
		grid-column-gap: var(--column-gap, 1.3rem);
	}
}

@media (max-width: 800px) {
	:root[data-layout="right-sidebar"] > body.grid {
		grid-template-areas: 'header' 'nav' 'main' 'sidebar' 'footer';
		grid-template-rows: minmax(var(--header-height, 400px), auto) var(--nav-height, 4rem) minmax(var(--main-height, 75vh), auto) minmax(var(--footer-height, 300px), auto);
		grid-template-columns: 100%;
		grid-column-gap: 0;
	}
}
