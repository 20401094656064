@import url("../shared.css");

@media screen {
	:root:not([data-layout]) > body.grid, :root[data-layout="default"] > body.grid {
		grid-template-areas: 'header header header' 'nav nav nav' '. main sidebar' 'footer footer footer';
		grid-template-rows: calc(var(--viewport-height, 100vh) - var(--nav-height, 4rem)) var(--nav-height, 4rem) minmax(var(--main-height, 75vh), auto) minmax(var(--footer-height, 300px), auto);
		grid-template-columns: auto minmax(var(--main-min-width, 320px), var(--main-max-width, 1200px)) minmax(var(--sidebar-min-width, 300px), var(--sidebar-max-width, 400px));
		grid-column-gap: 1.2em;
	}
}

@media (max-width: 800px) {
	:root:not([data-layout]) > body.grid, :root[data-layout="default"] > body.grid, body.grid {
		grid-template-areas: 'header' 'nav' 'main' 'sidebar' 'footer';
		grid-template-rows: calc(var(--viewport-height, 100vh) - var(--nav-height, 4rem)) var(--nav-height, 4rem) minmax(75vh, auto) minmax(200px, auto) minmax(300px, auto);
		grid-template-columns: 100%;
		grid-column-gap: 0;
	}
}
