@import url("../shared.css");

@media screen {
	:root[data-layout="sidebar-collapse"] > body.grid {
		grid-template-areas: "header header header" "nav nav nav" ". main ." "footer footer footer";
		grid-template-rows: minmax(var(--header-height, 400px), auto) var(--nav-height, 4rem) minmax(var(--main-height, 75vh), auto) minmax(var(--footer-height, 300px), auto);
		grid-template-columns: 1fr minmax(var(--main-min-width, 320px), var(--main-max-width, 1200px)) 1fr;
		grid-column-gap: var(--column-gap, 1.3rem);
		max-width: 100vw;
	}

	:root[data-layout="sidebar-collapse"] #sidebar {
		padding: 0;
		position: fixed;
		top: 1vh;
		bottom: 1vh;
		left: 100vw;
		z-index: calc(var(--z-top) - 1);
		margin: 0;
		height: 98vh;
		width: var(--sidebar-width, 400px);
		max-width: 95vw;
		transform: none;
		overflow-y: auto;
		transition: transform 400ms ease-in-out;
	}

	:root[data-layout="sidebar-collapse"] #sidebar.open {
		transform: translateX(-103%);
	}
}
